var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { size: "large", spinning: _vm.loading } }, [
    _c(
      "div",
      { staticClass: "home" },
      [
        _vm.user.sales_paid == 1
          ? [
              _c(
                "a-form",
                {
                  staticClass: "wrap-form",
                  attrs: { layout: "inline", form: _vm.form }
                },
                [
                  _c("a-form-item", { staticClass: "form-item" }, [
                    _c(
                      "div",
                      { staticClass: "wrap-input" },
                      [
                        _c("span", { staticClass: "span", class: [_vm.lang] }, [
                          _vm._v(_vm._s(_vm.$t("date")))
                        ]),
                        _c(
                          "a-range-picker",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["date"],
                                expression: "['date']"
                              }
                            ],
                            staticStyle: { width: "340px" },
                            attrs: {
                              size: "large",
                              format: "YYYY/MM/DD",
                              valueFormat: "YYYY/MM/DD"
                            },
                            on: { change: _vm.handleSelectChange }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "#0e756a" },
                              attrs: { slot: "suffixIcon", type: "calendar" },
                              slot: "suffixIcon"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("a-form-item", { staticClass: "form-item" }, [
                    _c(
                      "div",
                      { staticClass: "wrap-input" },
                      [
                        _c("span", { staticClass: "span", class: [_vm.lang] }, [
                          _vm._v(_vm._s(_vm.$t("filterLinkedinAccount")))
                        ]),
                        _c(
                          "a-select",
                          {
                            class: [_vm.lang],
                            staticStyle: { width: "360px" },
                            attrs: {
                              size: "large",
                              placeholder:
                                "Select a option and change input text above"
                            },
                            on: { change: _vm.handleSelectChange },
                            model: {
                              value: _vm.linkedin_id,
                              callback: function($$v) {
                                _vm.linkedin_id = $$v
                              },
                              expression: "linkedin_id"
                            }
                          },
                          [
                            _c(
                              "a-select-option",
                              { class: [_vm.lang], attrs: { value: "" } },
                              [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]
                            ),
                            _vm._l(_vm.linkList, function(item, index) {
                              return _c(
                                "a-select-option",
                                {
                                  key: index,
                                  class: [_vm.lang],
                                  attrs: { value: item.linkedin_id }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.linkedin_email) + " "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "dashbordData" }, [
                _c("ul", [
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("TotalNumber")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.customer_find
                              ? _vm.detailData.customer_find
                              : 0
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("ConnectionsRequest")) + " ")
                    ]),
                    _c("div", { staticClass: "data incluedRate" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.connections_request
                              ? _vm.detailData.connections_request
                              : 0
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("ConnectionsMade")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.connections_made
                              ? _vm.detailData.connections_made
                              : 0
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("Acceptedrate")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.accept_rate
                              ? _vm.detailData.accept_rate
                              : 0
                          ) + "%"
                        )
                      ])
                    ])
                  ]),
                  _c("li", { staticStyle: { "border-right": "none" } }, [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("NumberOfGreeters")) + " ")
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.greeting_count
                              ? _vm.detailData.greeting_count
                              : 0
                          )
                        )
                      ])
                    ])
                  ])
                ]),
                _c("ul", { staticStyle: { "margin-top": "26px" } }, [
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("Replies")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.replies ? _vm.detailData.replies : 0
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("Repliesrate")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.replies_rate
                              ? _vm.detailData.replies_rate
                              : 0
                          ) + "%"
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("Message")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.message ? _vm.detailData.message : 0
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("LeadsGenerated")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.leads ? _vm.detailData.leads : 0
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("li", { staticStyle: { "border-right": "none" } }, [
                    _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("ConversionRate")))
                    ]),
                    _c("div", { staticClass: "data" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.rate ? _vm.detailData.rate : 0
                          ) + "%"
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "wrap-table" }, [
                _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _c("svg-icon", { attrs: { iconClass: "active" } }),
                    _c("span", { class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("campaign")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-table", {
                      staticClass: "capinTable",
                      class: { arTable: _vm.lang == "AR" ? true : false },
                      attrs: {
                        scroll: {
                          x: 1120
                        },
                        size: "middle",
                        columns: _vm.columns,
                        rowKey: function(record, index) {
                          return index
                        },
                        "data-source": _vm.tableData,
                        pagination: false,
                        loading: _vm.loading,
                        "row-class-name": function(_record, index) {
                          return index % 2 !== 1 ? "table-bg" : null
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "status",
                            fn: function(text, record) {
                              return [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: record.status == 1,
                                        expression: "record.status == 1"
                                      }
                                    ],
                                    staticClass: "status active"
                                  },
                                  [
                                    _c("i", {
                                      class: {
                                        rtl: _vm.lang == "AR" ? true : false
                                      }
                                    }),
                                    _c("span", { class: _vm.lang }, [
                                      _vm._v(_vm._s(_vm.$t("Active")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: record.status == 0,
                                        expression: "record.status == 0"
                                      }
                                    ],
                                    staticClass: "status paused"
                                  },
                                  [
                                    _c("i", {
                                      class: {
                                        rtl: _vm.lang == "AR" ? true : false
                                      }
                                    }),
                                    _c("span", { class: _vm.lang }, [
                                      _vm._v(_vm._s(_vm.$t("Paused")))
                                    ])
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "campaign_name",
                            fn: function(text, record) {
                              return [
                                _vm._v(
                                  " " + _vm._s(record.campaign_name) + " "
                                ),
                                record && record.action_log
                                  ? _c("CampaignTableTip", {
                                      attrs: { record: record.action_log }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1131423160
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("app-footer", {
                      attrs: {
                        current: _vm.page,
                        pageSize: _vm.pageSize,
                        total: _vm.total
                      },
                      on: {
                        onPageChange: _vm.onPageChange,
                        onPageSizeChange: _vm.onPageSizeChange
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          : [
              _c("div", { staticClass: "nopay" }, [
                _c("div", { staticClass: "wrapText" }),
                _c("div", { staticClass: "textDes" }, [
                  _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("WhyUpgrade")))
                  ]),
                  _c(
                    "p",
                    {
                      style: { paddingTop: _vm.lang == "CN" ? "57px" : "42px" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("upgradeTitle")) + " ")]
                  )
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "margin-left": "0px" } }, [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.days))
                    ]),
                    _c("div", { staticClass: "flag" }, [
                      _vm._v(_vm._s(_vm.$t("day")))
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.hours))
                    ]),
                    _c("div", { staticClass: "flag" }, [
                      _vm._v(_vm._s(_vm.$t("Hrs")))
                    ])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.minutes))
                    ]),
                    _c("div", { staticClass: "flag" }, [
                      _vm._v(_vm._s(_vm.$t("Mins")))
                    ]),
                    _c("div", { staticClass: "split" }, [_vm._v(":")])
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.seconds))
                    ]),
                    _c("div", { staticClass: "flag" }, [
                      _vm._v(_vm._s(_vm.$t("Secs")))
                    ]),
                    _c("div", { staticClass: "split" }, [_vm._v(":")])
                  ])
                ]),
                _c("div", { staticClass: "btn" }, [
                  _c(
                    "span",
                    { class: [_vm.lang], on: { click: _vm.skipPay } },
                    [_vm._v(_vm._s(_vm.$t("UpgradeNow")))]
                  )
                ])
              ])
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }